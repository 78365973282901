<template>
  <tr :class="['session-table__tr', { 'session-table__tr_with-hover': isActions }]">
    <td class="session-table__td">
      {{ sessionItem.item.name }}
      <div class="font-small-2 pl-75 pr-75">
        <div
          v-for="option in sessionItem.options"
          :key="option"
        >
          {{ getOrderItemOptionOutput(option) }}
        </div>
        <div
          v-for="extra in sessionItem.extras"
          :key="extra"
        >
          {{ getOrderItemExtraOutput(extra) }}
        </div>
        <div
          v-if="sessionItem.note"
          class="font-weight-bolder"
        >
          {{ sessionItem.note }}
        </div>
      </div>
    </td>
    <td class="session-table__td">
      <div class="session-table__item-price">
        {{ sessionItem.quantity }} * <currency-symbol>{{ getFormattedPrice(sessionItem.price) }}</currency-symbol>
      </div>
    </td>
    <td class="session-table__td">
      <div class="session-table__item-price">
        <currency-symbol>{{ getFormattedPrice(sessionItem.quantity * sessionItem.price) }}</currency-symbol>
      </div>
      <div
        v-if="isActions"
        class="session-table__item-actions"
      >
        <icon-no-charge
          v-if="sessionItem.price"
          v-b-tooltip.hover.top="$t('orderNoChargeTooltip')"
          class="fill-primary mr-50"
          role="button"
          @click.native="onClickNoCharge"
        />
        <feather-icon
          class="text-danger"
          icon="TrashIcon"
          role="button"
          size="16"
          @click="onClickDelete"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import IconNoCharge from '@/components/icon/IconNoCharge.vue'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import mixinFormatter from '@/mixins/formatter'
import { mapGetters } from 'vuex'
import { getOrderItemOptionOutput, getOrderItemExtraOutput } from '@/order'

export default {
  name: 'OrdersSessionAddedItem',
  components: {
    IconNoCharge,
    CurrencySymbol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinFormatter,
  ],
  props: {
    sessionItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    isActions: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      menuItems: 'menuManagement/menuItems',
    }),
  },
  methods: {
    getOrderItemOptionOutput(optionId) {
      return getOrderItemOptionOutput(optionId, this.sessionItem.item)
    },
    getOrderItemExtraOutput(extraId) {
      return getOrderItemExtraOutput(extraId, this.sessionItem.item, this.menuItems)
    },
    onClickNoCharge() {
      this.$emit('noCharge')
    },
    onClickDelete() {
      this.$emit('delete')
    },
  },
}
</script>

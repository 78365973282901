import { v4 as uuidv4 } from 'uuid'

function stringifyOrderItem(value, id) {
  let result = id || value.offerId

  result += [...value.options].sort().join('')
  result += [...value.extras].sort().join('')
  result += value.note

  return result
}

function getOrderItemOptionOutput(optionId, item) {
  let result = ''

  const option = item.options.find(i => i.items.find(j => j.id === optionId))
  if (option) {
    let prefix = ''
    if (option.type === 'add') {
      prefix = '+ '
    }
    if (option.type === 'remove') {
      prefix = '- '
    }

    const optionItem = option.items.find(i => i.id === optionId)
    if (optionItem) {
      result = `${prefix}${optionItem.name}`
    }
  }

  return result
}

function getOrderItemExtraOutput(extraId, item, items) {
  let result = ''

  const extra = item.extras.find(i => i.items.find(j => j.id === extraId))
  if (extra) {
    const extraItem = items.find(i => i.id === extraId || i.originalMenuItemId === extraId)
      || extra.items.find(i => i.id === extraId)
    if (extraItem) {
      result = extraItem.name
    }
  }

  return result
}

function getOrderSubtotal(items) {
  return items.reduce((result, item) => result + item.price, 0)
}

function getOrderSurcharges(surcharges, orderSubtotal) {
  return surcharges.map(i => {
    if (i.type === 'ADDITION') {
      return {
        ...i,
        id: uuidv4(),
        renderValue: i.value,
      }
    }

    if (i.type === 'PERCENTAGE') {
      return {
        ...i,
        id: uuidv4(),
        renderValue: orderSubtotal * (i.value / 100),
      }
    }

    return {
      ...i,
      id: uuidv4(),
    }
  })
}

function getOrderTotal(orderSubtotal, orderSurcharges) {
  let result = orderSubtotal

  orderSurcharges.forEach(i => {
    if (i.type === 'ADDITION') {
      result += i.renderValue
    }
    if (i.type === 'PERCENTAGE') {
      result += i.renderValue
    }
    if (i.type === 'PERCENTAGE_DISCOUNT') {
      // result += i.renderValue
    }
  })

  return result
}

export {
  stringifyOrderItem,
  getOrderItemOptionOutput,
  getOrderItemExtraOutput,
  getOrderSubtotal,
  getOrderSurcharges,
  getOrderTotal,
}

<template>
  <svg
    class="icon-custom"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.91634 11.5C8.91634 11.5 9.83301 11.5 9.83301 10.5833C9.83301 9.66667 8.91634 6.91667 5.24967 6.91667C1.58301 6.91667 0.666342 9.66667 0.666342 10.5833C0.666342 11.5 1.58301 11.5 1.58301 11.5H8.91634ZM5.24967 6C4.52033 6 3.82086 5.71027 3.30513 5.19454C2.78941 4.67882 2.49967 3.97935 2.49967 3.25C2.49967 2.52065 2.78941 1.82118 3.30513 1.30546C3.82086 0.789731 4.52033 0.5 5.24967 0.5C5.97902 0.5 6.67849 0.789731 7.19422 1.30546C7.70994 1.82118 7.99967 2.52065 7.99967 3.25C7.99967 3.97935 7.70994 4.67882 7.19422 5.19454C6.67849 5.71027 5.97902 6 5.24967 6Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5517 11.5002C10.6876 11.2141 10.7553 10.9003 10.7497 10.5836C10.7497 9.34148 10.1263 8.06273 8.97501 7.17356C9.54967 6.9965 10.1484 6.9099 10.7497 6.9169C14.4163 6.9169 15.333 9.6669 15.333 10.5836C15.333 11.5002 14.4163 11.5002 14.4163 11.5002H10.5517Z"
    />
    <path
      d="M11.2083 5.99984C10.6005 5.99984 10.0177 5.75839 9.58788 5.32862C9.15811 4.89885 8.91667 4.31596 8.91667 3.70817C8.91667 3.10038 9.15811 2.51749 9.58788 2.08772C10.0177 1.65795 10.6005 1.4165 11.2083 1.4165C11.8161 1.4165 12.399 1.65795 12.8288 2.08772C13.2586 2.51749 13.5 3.10038 13.5 3.70817C13.5 4.31596 13.2586 4.89885 12.8288 5.32862C12.399 5.75839 11.8161 5.99984 11.2083 5.99984Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconUsers',
}
</script>

<template>
  <div class="orders-history">
    <b-row>
      <b-col :cols="selected ? 8 : 12">
        <!--<b-card>
          <div class="d-flex align-items-center justify-content-between">
            <b-input-group
              size="sm"
              class="mr-2"
              style="max-width: 300px"
            >
              <b-form-input
                v-model="filter"
                :placeholder="$t('Type to Search')"
              />
              <b-input-group-append>
                <b-button
                  :variant="filter.length ? 'outline-primary' : 'outline-secondary'"
                  @click="onClickClearFilter"
                >
                  {{ $t('Clear') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <date-picker
              v-model="datePickerModel"
              :range="true"
              type="date"
              :formatter="datePickerFormatter"
              :clearable="true"
              :editable="false"
              :lang="datePickerLang"
              prefix-class="xmx"
              :range-separator="rangeSeparator"
              :placeholder="$t('Select date range')"
              style="max-width: 300px"
            />
          </div>
        </b-card>-->
        <b-table
          ref="table"
          class="bg-white mb-2"
          :busy="isFetching"
          :hover="true"
          :responsive="true"
          :selectable="true"
          :items="itemsMapped"
          :fields="fields"
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template #cell(amount)="row">
            <currency-symbol>{{ getFormattedPrice(row.item.amount) }}</currency-symbol>
          </template>
          <template #table-busy>
            <div class="text-center">
              <b-spinner variant="primary" />
            </div>
          </template>
        </b-table>
        <b-row class="d-flex align-items-center">
          <b-col>
            <span v-if="!isFetching">
              <i18n path="orderHistoryItemsOnPageOfTotal">
                <template v-slot:slot1>
                  <span class="font-weight-bolder">{{ items.length }}</span>
                </template>
                <template v-slot:slot2>
                  <span class="font-weight-bolder">{{ totalRows }}</span>
                </template>
              </i18n>
            </span>
          </b-col>
          <b-col cols="auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :disabled="isFetching"
              align="center"
              class="my-0"
            />
          </b-col>
          <b-col class="d-flex justify-content-end">
            <div class="d-flex align-items-center">
              <small class="mr-50 text-nowrap">
                {{ $t('orderHistoryItemsPerPageLabel') }}
              </small>
              <b-form-select
                v-model="perPage"
                size="sm"
                :disabled="isFetching"
                :options="pageOptions"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="selected"
        cols="4"
      >
        <div class="session">
          <div class="session__header">
            <div class="flex-grow-1">
              <b-row class="d-flex align-items-center">
                <b-col>
                  <h5 class="mb-0">
                    {{ selected.zone.name }}: #{{ selected.table.name }}
                  </h5>
                </b-col>
                <b-col
                  cols="auto"
                  class="d-flex"
                >
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    role="button"
                    @click="onClickCloseSidebar"
                  />
                </b-col>
              </b-row>
              <b-row class="align-items-center mt-75">
                <b-col>
                  <div class="d-flex align-items-center">
                    <icon-users class="mr-50" /> {{ selected.guests || '-' }}
                  </div>
                </b-col>
                <b-col cols="auto">
                  {{ $t('Order') }} #{{ selected.orderNumber }}
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="session__body">
            <div
              v-if="selectedItems.length"
              class="session-heading"
            >
              <span class="session-heading__value">
                {{ $t('orderHistoryOrderedItemsListName') }}
              </span>
            </div>
            <table class="session-table">
              <tbody class="session-table__tbody">
                <orders-session-added-item
                  v-for="item in selectedItems"
                  :key="item.id"
                  :session-item="item"
                  :is-actions="false"
                />
              </tbody>
            </table>
          </div>
          <div class="session__footer">
            <div class="session-total">
              <div class="session-total__row">
                <div class="session-total__name">
                  {{ $t('ordersSubtotalLabel') }}:
                </div>
                <div class="session-total__value">
                  <currency-symbol>{{ getFormattedPrice(selected.itemsAmount) }}</currency-symbol>
                </div>
              </div>
              <template v-if="selected.isHandledThroughStripe">
                <div
                  v-for="surcharge in selectedSurcharges"
                  :key="surcharge.id"
                  class="session-total__row"
                >
                  <div class="session-total__name">
                    {{ surcharge.name }}<span
                      v-if="surcharge.type === 'PERCENTAGE' || surcharge.type === 'PERCENTAGE_DISCOUNT'"
                    > ({{ surcharge.value }}%)</span>:
                  </div>
                  <div class="session-total__value">
                    <currency-symbol>{{ getFormattedPrice(surcharge.cost) }}</currency-symbol>
                  </div>
                </div>
                <div class="session-total__row">
                  <div class="session-total__name">
                    {{ $t('ordersTotalLabel') }}:
                  </div>
                  <div class="session-total__value">
                    <currency-symbol>{{ getFormattedPrice(selected.amount) }}</currency-symbol>
                  </div>
                </div>
              </template>
            </div>
            <b-button
              variant="primary"
              block
              @click="onClickCloseSidebar"
            >
              {{ $t('orderHistoryCloseButtonName') }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  // BCard,
  // BInputGroup,
  // BFormInput,
  // BInputGroupAppend,
  BTable,
  BSpinner,
  BRow,
  BCol,
  BPagination,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
// import DatePicker from 'vue2-datepicker'
import mixinDatepicker from '@/mixins/datepicker'
import IconUsers from '@/components/icon/IconUsers.vue'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import mixinFormatter from '@/mixins/formatter'
import OrdersSessionAddedItem from '@/components/orders/OrdersSessionAddedItem.vue'
import { stringifyOrderItem } from '@/order'
import { v4 as uuidv4 } from 'uuid'

import COMPLETED_ORDERS from '@/gql/query/completedOrders.gql'

export default {
  name: 'OrdersHistory',
  components: {
    // BCard,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BTable,
    BSpinner,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    // DatePicker,
    IconUsers,
    CurrencySymbol,
    OrdersSessionAddedItem,
  },
  mixins: [
    mixinDatepicker,
    mixinFormatter,
  ],
  data() {
    return {
      isFetching: true,

      perPage: 8,
      pageOptions: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20],
      totalRows: 1,
      currentPage: 1,
      filter: '',
      datePickerModel: [null, null],
      selected: null,

      items: [],
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
    }),
    fields() {
      return [
        {
          key: 'orderNumber',
          label: this.$t('orderHistoryTableHeadNumber'),
          sortable: false,
        },
        {
          key: 'orderDate',
          label: this.$t('orderHistoryTableHeadDate'),
          sortable: false,
        },
        {
          key: 'orderType',
          label: this.$t('orderHistoryTableHeadType'),
          sortable: false,
        },
        {
          key: 'location',
          label: this.$t('orderHistoryTableHeadLocation'),
          sortable: false,
        },
        {
          key: 'waiterName',
          label: this.$t('orderHistoryTableHeadWaiter'),
          sortable: false,
        },
        {
          key: 'amount',
          label: this.$t('orderHistoryTableHeadTotal'),
          sortable: false,
        },
      ]
    },
    itemsMapped() {
      return this.items.map(i => ({
        ...i,
        orderDate: DateTime
          .fromISO(i.startTime)
          .setLocale(navigator.language)
          .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
        orderType: this.$t(`_orderType.${i.orderType}`),
        location: `${i.zone.name}: #${i.table.name}`,
        waiterName: i.waiterName || '-',
      }))
    },
    selectedItems() {
      if (this.selected) {
        const items = this.selected.items.map(i => [stringifyOrderItem(i, i.item.id), i])
        const itemsWithQuantity = items
          .map(i => [i[0], { ...i[1], quantity: items.map(j => j[0]).filter(j => j === i[0]).length }])
        return [...new Map(itemsWithQuantity).values()].map(i => ({ ...i, price: i.overridePrice }))
      }
      return []
    },
    selectedSurcharges() {
      if (this.selected) {
        return this.selected.surcharges.map(i => ({ ...i, id: uuidv4() }))
      }
      return []
    },
    // datePickerFormatter() {
    //   return {
    //     stringify: date => (date
    //       ? DateTime
    //         .fromJSDate(date)
    //         .setLocale(navigator.language)
    //         .toLocaleString(DateTime.DATE_SHORT)
    //       : ''
    //     ),
    //     parse: value => (value ? new Date(value) : null),
    //   }
    // },
  },
  watch: {
    restaurant() {
      this.setup()
    },
    perPage() {
      this.setup()
    },
    currentPage() {
      this.setup()
    },
  },
  created() {
    this.setup()
  },
  methods: {
    async setup() {
      if (!this.restaurant) {
        return
      }

      this.isFetching = true

      const response = await this.$apollo.query({
        query: COMPLETED_ORDERS,
        variables: {
          restaurantId: this.restaurant.id,
          limit: this.perPage,
          page: this.currentPage,
        },
      })

      this.totalRows = response.data.completedOrders.total
      this.perPage = response.data.completedOrders.pageSize
      this.items = [...response.data.completedOrders.data]

      this.isFetching = false
    },
    // onClickClearFilter() {
    //   this.filter = ''
    // },
    onRowSelected(items) {
      this.selected = items.length ? items[0] : null
    },
    clearTableSelected() {
      this.$refs.table.clearSelected()
    },
    onClickCloseSidebar() {
      this.clearTableSelected()
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/vue2-datepicker'
  @import '@/assets/sass/orders-session.sass'

  .orders-history
    .table
      thead,
      tfoot
        th
          text-transform: none
</style>

<template>
  <svg
    class="icon-custom"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.666992 8.71419C0.666992 4.64753 3.93366 1.38086 8.00033 1.38086C12.067 1.38086 15.3337 4.64753 15.3337 8.71419C15.3337 12.7809 12.067 16.0475 8.00033 16.0475C3.93366 16.0475 0.666992 12.7809 0.666992 8.71419ZM2.00033 8.71419C2.00033 12.0475 4.66699 14.7142 8.00033 14.7142C11.3337 14.7142 14.0003 12.0475 14.0003 8.71419C14.0003 5.38086 11.3337 2.71419 8.00033 2.71419C4.66699 2.71419 2.00033 5.38086 2.00033 8.71419Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.52827 4.24409C3.78862 3.98374 4.21073 3.98374 4.47108 4.24409L5.47108 5.24409C5.73143 5.50444 5.73143 5.92655 5.47108 6.1869C5.21073 6.44725 4.78862 6.44725 4.52827 6.1869L3.52827 5.1869C3.26792 4.92655 3.26792 4.50444 3.52827 4.24409ZM7.99967 4.71549C8.36786 4.71549 8.66634 5.01397 8.66634 5.38216V6.06062C9.17685 6.12764 9.60635 6.46015 9.83763 6.91143C10.0056 7.2391 9.87607 7.64086 9.5484 7.80878C9.22074 7.97671 8.81898 7.84722 8.65105 7.51956C8.5909 7.40219 8.50859 7.38018 8.47007 7.38167C8.4615 7.382 8.45292 7.38216 8.44434 7.38216H7.55501C7.52065 7.38216 7.47353 7.39628 7.42482 7.45105C7.37505 7.50701 7.33301 7.59836 7.33301 7.71549C7.33301 7.83116 7.37474 7.92253 7.42492 7.97897C7.47403 8.03422 7.52127 8.04816 7.55501 8.04816H8.44434C9.37478 8.04816 9.99967 8.86988 9.99967 9.71483C9.99967 10.4903 9.47262 11.2463 8.66634 11.3648V12.0488C8.66634 12.417 8.36786 12.7155 7.99967 12.7155C7.63148 12.7155 7.33301 12.417 7.33301 12.0488V11.369C6.8225 11.302 6.393 10.9695 6.16172 10.5182C5.99379 10.1906 6.12328 9.7888 6.45095 9.62087C6.77861 9.45295 7.18037 9.58244 7.3483 9.9101C7.40845 10.0275 7.49076 10.0495 7.52928 10.048C7.53785 10.0477 7.54643 10.0475 7.55501 10.0475H8.44434C8.4784 10.0475 8.52561 10.0334 8.57453 9.97844C8.62452 9.92224 8.66634 9.83097 8.66634 9.71483C8.66634 9.59816 8.62439 9.50672 8.57443 9.45052C8.52553 9.39552 8.47838 9.38149 8.44434 9.38149H7.55501C6.62457 9.38149 5.99967 8.55911 5.99967 7.71549C5.99967 6.93879 6.52673 6.18329 7.33301 6.06487V5.38216C7.33301 5.01397 7.63148 4.71549 7.99967 4.71549ZM10.5283 11.2441C10.7886 10.9837 11.2107 10.9837 11.4711 11.2441L12.4711 12.2441C12.7314 12.5044 12.7314 12.9266 12.4711 13.1869C12.2107 13.4472 11.7886 13.4472 11.5283 13.1869L10.5283 12.1869C10.2679 11.9266 10.2679 11.5044 10.5283 11.2441Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconNoCharge',
}
</script>
